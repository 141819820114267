<template>
  <div>
    <div
      class="layout-sticky w-full min-h-screen"
      :class="{
        'scrolled': isScrolled,
        'no-navbar': !$slots.navbar,
      }"
    >
      <TheShamrockBackground
        v-if="shamrock"
        class="the-shamrock"
        shamrock-class="shamrock"
      />

      <div
        class="header-container flex w-screen"
        data-containerid="page_header"
      >
        <div class="header w-full mx-auto">
          <slot name="header" />
        </div>
      </div>

      <main class="content-wrap px-xs" data-containerid="page_content">
        <ActiveAnnouncement class="pb-md" />
        <slot />
      </main>

      <TheNavbar v-if="$slots.navbar">
        <slot name="navbar" />
      </TheNavbar>
    </div>

    <div v-if="$slots.after" data-containerid="page_footer">
      <slot name="after" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'

type StickyLayoutProps = {
  headerHeight?: string
  shamrock?: boolean
}

const IS_SCROLLED_AFTER_PX = 20

const props = withDefaults(defineProps<StickyLayoutProps>(), {
  headerHeight: '5rem',
  shamrock: false,
})

const { headerHeight } = toRefs(props)
const isScrolled = ref(false)

const handleIsScrolled = () => {
  isScrolled.value = window.scrollY > IS_SCROLLED_AFTER_PX
}

useEventListener(window, 'scroll', handleIsScrolled)

onMounted(handleIsScrolled)
</script>

<style lang="scss" scoped>
.layout-sticky {
  position: relative;
  display: grid;
  grid-template-rows: v-bind(headerHeight) 1fr $main-bottom-navigation-height;

  .header-container {
    @include transition-default(background-color);

    position: fixed;
    top: 0;
    z-index: 120;
    min-height: v-bind(headerHeight);

    .header {
      max-width: $main-content-width;
    }
  }

  .content-wrap {
    grid-row: 2;
  }

  &.no-navbar {
    grid-template-rows: v-bind(headerHeight) 1fr;

    .content-wrap {
      padding-bottom: $main-bottom-navigation-height;
    }
  }

  &.scrolled {
    .header-container {
      background-color: color('white');
      box-shadow: $shadow-lg;
    }
  }
}

.the-shamrock {
  margin-top: 8.75rem;

  :deep(.shamrock) {
    opacity: 0.15;
  }
}
</style>
